body {
  font-family: "Lexend", sans-serif;
}
footer {
  display: flex;
  justify-content: center;
  max-width: 1300px;
  width: 100%;
  margin: auto;
  padding: 0px 16px;
}
footer .footer-content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid;
  border-image-source: linear-gradient(to right, #835edd, #1dcaff, #ef546b);
  border-image-slice: 1;
  margin-bottom: 16px;
  padding: 0 8px;
  width: 100%;
}
footer .footer-icon-container {
  display: flex;
  gap: 12px;
  margin-top: 10px;
}

footer .w-inline-block {
  max-width: 100%;
  display: inline-block;
}

footer .nw_footersociallinks {
  color: #313435;
}
footer .nw_socialcircularicon {
  width: 24px;
  height: 24px;
  color: var(--quote-grey);
  border-radius: 200px;
  transition: box-shadow 0.2s, transform 0.2s;
}
footer .nw_socialcircularicon:hover {
  transform: scale(1.1);
  box-shadow: 1px 0 9px 1px rgba(0, 0, 0, 0.24);
}
footer .copyrightText {
  color: #4e5456;
  font-size: 14px;
}

@media screen and (max-width: 500px) {
  footer .footer-content-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
