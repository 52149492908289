.login {
}

.headerBar {
  margin: 0;
  padding: 0;
  border-bottom: solid rgba(73, 105, 228, 0.2) 1px;
  z-index: 9;
  left: 0;
  position: relative;
  background: rgb(248, 248, 255);

  /*background: #243b55;  !* fallback for old browsers *!*/
  /*background: -webkit-linear-gradient(to right, #243B55, #141E30);  !* Chrome 10-25, Safari 5.1-6 *!*/
  /*background: linear-gradient(to right, #243B55, #141E30); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
  /*background: -moz-linear-gradient(90deg, rgba(248,248,255,1) 0%, rgba(236,241,255,1) 50%, rgba(248,248,255,1) 100%);*/
  /*background: -webkit-linear-gradient(90deg, rgba(248,248,255,1) 0%, rgba(236,241,255,1) 50%, rgba(248,248,255,1) 100%);*/
  /*background: linear-gradient(90deg, rgba(248,248,255,1) 0%, rgba(236,241,255,1) 50%, rgba(248,248,255,1) 100%);*/
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8f8ff",endColorstr="#f8f8ff",GradientType=1);*/
}
