.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  min-height: calc(100vh - 0px);
  flex-direction: column;
}

nav {
  height: 40px;
  padding: 10px;
}

main {
  flex: 1;
  width: auto;
  overflow-x: scroll;
}

.footerText {
  font-size: 15px;
}

footer.loginFooter {
  text-align: center;
  padding: 10px;
  line-height: 30px;
  height: 30px;
  background: #4776e6; /* fallback for old browsers */
  color: white;
  background: -webkit-linear-gradient(
    to right,
    #8e54e9,
    #4776e6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #8e54e9,
    #4776e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

footer.loginFooter a {
  color: white;
}

footer.loginFooter a:hover {
  color: #80f9ff;
}

footer.innerFooter {
  padding: 8px 12px;
  margin-top: 24px;
  border-top: 1px solid lightgray;
  color: gray;
  font-size: 12px;
}

footer.innerFooter a {
  color: gray;
  margin-left: 12px;
}
footer.innerFooter a:hover {
  color: black;
}

.fullPanel {
}

.rightPanel {
  background: #00d2ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a7bd5,
    #00d2ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a7bd5,
    #00d2ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
}

.imageDiv {
  /*padding: 120px 0px 0px;*/
  padding-bottom: 20px;
}

.imageHomeOp {
  opacity: 0.9;
}

.highlightText {
  text-align: center;
  color: #80f9ff;
  font-size: 24px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
}

a {
  text-decoration: none;
  opacity: 0.8;
}

a:hover {
  opacity: 1;
}

a:visited {
  border: none;
}

a:active {
}

.paperOrgHeader {
  font-size: 13px;
  font-family: "Lexend", sans-serif;
  font-weight: 700;
  padding-left: 10px;
  margin-bottom: 15px;
  color: #33143f;
  opacity: 0.8;
}

.paperOrgValue {
  padding-left: 10px;
  font-size: 17px;
  margin-top: 0;
  display: flex;
}

.clusterBottom {
  min-height: 110px;
  background: rgb(245, 245, 245);
  background: -moz-linear-gradient(
    90deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(243, 243, 246, 1) 50%,
    rgba(240, 240, 244, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(243, 243, 246, 1) 50%,
    rgba(240, 240, 244, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(243, 243, 246, 1) 50%,
    rgba(240, 240, 244, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f5",endColorstr="#f0f0f4",GradientType=1);
}

.clusterTop {
  /*background: rgb(245,245,245);*/
  /*background: -moz-linear-gradient(90deg, rgba(245,245,245,1) 0%, rgba(243,243,246,1) 50%, rgba(240,240,244,1) 100%);*/
  /*background: -webkit-linear-gradient(90deg, rgba(245,245,245,1) 0%, rgba(243,243,246,1) 50%, rgba(240,240,244,1) 100%);*/
  /*background: linear-gradient(90deg, rgba(245,245,245,1) 0%, rgba(243,243,246,1) 50%, rgba(240,240,244,1) 100%);*/
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f5",endColorstr="#f0f0f4",GradientType=1);*/
}

.leftDrawer {
  padding-left: 5px;
}
.leftDrawer > .MuiPaper-root {
  padding-left: 5px;
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /*background: #18001f;  !* fallback for old browsers *!*/
  /*background: rgb(248,248,248);*/
  /*background: -moz-linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(227,227,227,1) 36%);*/
  /*background: -webkit-linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(227,227,227,1) 36%);*/
  /*background: linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(227,227,227,1) 36%);*/
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8f8f8",endColorstr="#e3e3e3",GradientType=1);*/
}
.leftDrawer .MuiListItemIcon-root {
  min-width: 50px;
}

.headerText {
  font-family: "Lexend", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  margin-top: 12px;
  display: flex;
  height: 100%;
}

.headerValue {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 5px;
}

.createEnvInput .MuiInputBase-root {
  padding: 3px;
  height: 30px;
}
.createEnvInput .MuiAutocomplete-option {
  /*padding: 2px;*/
  /*height: 30px;*/
}

.collapsedTable {
  border-collapse: collapse;
}

.danger-text {
  color: #ff0000;
}

.pageHeaderSection {
  padding: 20px;
  display: table;
  width: 100%;
  border-bottom: 1px solid rgb(200, 200, 200, 0.5);
}
.pageHeaderTitle {
  width: 50%;
  float: left;
}
.pageHeaderActions {
  text-align: right;
  width: 50%;
  float: right;
}
@media (max-width: 800px) {
  .pageHeaderTitle {
    text-align: left;
    width: 100%;
  }
  .pageHeaderActions {
    flex: 1;
    text-align: left;
    width: 100%;
  }
}
.clusterSearch {
  width: 100%;
  box-sizing: border-box;
  display: block;
}
.clusterSearchitem {
  width: 30%;
}
@media screen and (max-width: 900px) {
  .clusterSearchitem {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .clusterSearchitem div {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 901px) and (max-width: 1000px) {
  .clusterSearchitem {
    width: 45%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .clusterSearch {
    max-width: 95%;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1100px) {
  .clusterSearchitem {
    width: 46%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .clusterSearch {
    max-width: 95%;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .clusterSearchitem {
    width: 33%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .clusterSearch {
    min-width: 1100px;
    width: auto;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 1200px) {
  .clusterSearchitem {
    width: 33%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .clusterSearch {
    min-width: 1200px;
    width: auto;
    box-sizing: border-box;
  }
}

* {
  box-sizing: border-box;
}
.clustersearchcontainer {
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  grid-gap: 1em;
}

.clustersearchField {
  border-radius: 10px;
  color: #fff;
}
.OrganizationInfoHeading {
  margin-top: 0 !important;
}
.OrganizationInfoHeading div {
  padding-top: 0 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  min-height: calc(100vh - 0px);
  flex-direction: column;
}

nav {
  height: 40px;
  padding: 10px;
}

main {
  flex: 1;
  width: auto;
  overflow-x: scroll;
}

.footerText {
  font-size: 15px;
}

footer.loginFooter {
  text-align: center;
  padding: 10px;
  line-height: 30px;
  height: 30px;
  background: #4776e6; /* fallback for old browsers */
  color: white;
  background: -webkit-linear-gradient(
    to right,
    #8e54e9,
    #4776e6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #8e54e9,
    #4776e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

footer.loginFooter a {
  color: white;
}

footer.loginFooter a:hover {
  color: #80f9ff;
}

footer.innerFooter {
  padding: 8px 12px;
  margin-top: 24px;
  border-top: 1px solid lightgray;
  color: gray;
  font-size: 12px;
}

footer.innerFooter a {
  color: gray;
  margin-left: 12px;
}
footer.innerFooter a:hover {
  color: black;
}

.fullPanel {
}

.rightPanel {
  background: #00d2ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a7bd5,
    #00d2ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a7bd5,
    #00d2ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
}

.imageDiv {
  /*padding: 120px 0px 0px;*/
  padding-bottom: 20px;
}

.imageHomeOp {
  opacity: 0.9;
}

.highlightText {
  text-align: center;
  color: #80f9ff;
  font-size: 24px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
}

a {
  text-decoration: none;
  opacity: 0.8;
}

a:hover {
  opacity: 1;
}

a:visited {
  border: none;
}

a:active {
}

.paperOrgHeader {
  font-size: 14px;
  font-family: "Lexend", sans-serif;
  font-weight: 500;
  padding-left: 10px;
  margin-bottom: 15px;
  color: #33143f;
  opacity: 0.8;
}

.paperOrgValue {
  padding-left: 10px;
  font-size: 17px;
  margin-top: 0;
  display: flex;
}

.clusterBottom {
  min-height: 110px;
  background: rgb(245, 245, 245);
  background: -moz-linear-gradient(
    90deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(243, 243, 246, 1) 50%,
    rgba(240, 240, 244, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(243, 243, 246, 1) 50%,
    rgba(240, 240, 244, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(243, 243, 246, 1) 50%,
    rgba(240, 240, 244, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f5",endColorstr="#f0f0f4",GradientType=1);
}

.clusterTop {
  /*background: rgb(245,245,245);*/
  /*background: -moz-linear-gradient(90deg, rgba(245,245,245,1) 0%, rgba(243,243,246,1) 50%, rgba(240,240,244,1) 100%);*/
  /*background: -webkit-linear-gradient(90deg, rgba(245,245,245,1) 0%, rgba(243,243,246,1) 50%, rgba(240,240,244,1) 100%);*/
  /*background: linear-gradient(90deg, rgba(245,245,245,1) 0%, rgba(243,243,246,1) 50%, rgba(240,240,244,1) 100%);*/
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f5",endColorstr="#f0f0f4",GradientType=1);*/
}

.leftDrawer {
  padding-left: 5px;
}
.leftDrawer > .MuiPaper-root {
  padding-left: 5px;
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /*background: #18001f;  !* fallback for old browsers *!*/
  /*background: rgb(248,248,248);*/
  /*background: -moz-linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(227,227,227,1) 36%);*/
  /*background: -webkit-linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(227,227,227,1) 36%);*/
  /*background: linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(227,227,227,1) 36%);*/
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8f8f8",endColorstr="#e3e3e3",GradientType=1);*/
}
.leftDrawer .MuiListItemIcon-root {
  min-width: 50px;
}

.headerText {
  font-family: "Lexend", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
  display: flex;
  height: 100%;
}

.headerValue {
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 5px;
}

.createEnvInput .MuiInputBase-root {
  padding: 3px;
  height: 30px;
}
.createEnvInput .MuiAutocomplete-option {
  /*padding: 2px;*/
  /*height: 30px;*/
}

.collapsedTable {
  border-collapse: collapse;
}

.danger-text {
  color: #ff0000;
}

.pageHeaderSection {
  padding: 20px;
  display: table;
  width: 100%;
  border-bottom: 1px solid rgb(200, 200, 200, 0.5);
}
.pageHeaderTitle {
  width: 50%;
  float: left;
}
.pageHeaderActions {
  text-align: right;
  width: 50%;
  float: right;
}
@media (max-width: 800px) {
  .pageHeaderTitle {
    text-align: left;
    width: 100%;
  }
  .pageHeaderActions {
    flex: 1;
    text-align: left;
    width: 100%;
  }
}
.OrganizationInfoHeading {
  margin-top: 0 !important;
}
.OrganizationInfoHeading div {
  padding-top: 0 !important;
}

.clusterSearch {
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.clusterSearchitem {
  width: 30%;
}

@media screen and (max-width: 900px) {
  .clusterSearchitem {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .clusterSearchitem div {
    padding-left: 0 !important;
  }
}
@media screen and (min-width: 901px) and (max-width: 1000px) {
  .clusterSearchitem {
    width: 45%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .clusterSearch {
    max-width: 95%;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1100px) {
  .clusterSearchitem {
    width: 46%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .clusterSearch {
    max-width: 95%;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .clusterSearchitem {
    width: 33%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .clusterSearch {
    min-width: 1100px;
    width: auto;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 1200px) {
  .clusterSearchitem {
    width: 33%;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .clusterSearch {
    min-width: 1200px;
    width: auto;
    box-sizing: border-box;
  }
}
.clustersearchcontainer {
  display: flex;
}
.clustersearchField {
  border-radius: 10px;
  color: #fff;
}

.btncreateNewCluster {
  text-align: left;
}
.conductor-cluster-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.conductor-cluster-container > div {
  width: 100px;
}
.ConductorclusterEnv {
  margin-left: auto;
  text-align: right;
}
@media screen and (max-width: 1000px) {
  .ConductorclusterEnv {
    margin-left: 0;
    text-align: left;
  }
}
